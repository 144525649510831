const uppercaseRegex = new RegExp('(?=.*[A-Z])')
const lowercaseRegex = new RegExp('(?=.*[a-z])')
const characterCountRegex = new RegExp('(^.{8,}$)')
const includesNumberRegex = new RegExp('(?=.*[0-9])')
const noConsecutiveCharactersRegex = new RegExp('(?:([[a-zA-Z0-9\\W\\S_])\\1(\\1))+')

export const passwordRule = `^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?!.*(.)\\1)[0-9a-zA-Z!@#$%&*]{8,}$`

export type passwordRule = {
  label: string,
  test: (password: string) => boolean
}

export const passwordRules: passwordRule[] = [
  {
    label: 'Is longer than 8 characters',
    test: (password) => characterCountRegex.test(password)
  },
  {
    label: 'Contains at least one uppercase letter',
    test: (password) => uppercaseRegex.test(password)
  },
  {
    label: 'Contains at least one lowercase letter',
    test: (password) => lowercaseRegex.test(password)
  },
    {
    label: 'Contains at least one number',
    test: (password) => includesNumberRegex.test(password)
  },
  {
    label: 'Does not contain more than 2 of the same characters in a row',
    test: (password) => !noConsecutiveCharactersRegex.exec(password)?.length && password.length > 0
  }
]

export const validPassword = (password: string) => passwordRules.every(({ test }) => test(password))

export const passwordMatch = (password: string, confirm_password: string) => password === confirm_password
