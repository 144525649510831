import { passwordRules, validPassword, passwordMatch } from '@/helpers/password'

export class ResetPasswordRequest {
  public token: string
  public username: string
  public password: string = ''
  public repeat_password: string = ''

  constructor({ token = '', username = '' }: { token?: string; username?: string }) {
    this.token = token
    this.username = username
  }

  public get isValid() {
    return !!this.token && !!this.username && this.validPassword && this.matchingPasswords
  }

  public get validPassword() {
    return validPassword(this.password)
  }

  public get matchingPasswords() {
    return passwordMatch(this.password, this.repeat_password)
  }
}

