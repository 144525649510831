







































































import { AuthService } from '@/services/auth'
import { Route } from 'vue-router'
import { stateModule, userModule } from '@/store'
import { Component, Vue } from 'vue-property-decorator'
import { passwordRules, validPassword, passwordMatch, passwordRule } from '@/helpers/password'

import Loader from '@/components/loaders/Loader.vue'
import TextInput from '@/components/inputs/Text.vue'

import { ResetPasswordRequest } from '@/requests/auth/ResetPasswordRequest'

const authService: AuthService = new AuthService()

@Component({
  components: {
    Loader,
    TextInput,
  },
})
export default class Reset extends Vue {
  private submitted: boolean = false

  private body: ResetPasswordRequest = new ResetPasswordRequest({})
  private message: string = ''

  private passwordPattern: string = passwordRule

  private errors: ErrorResponse = {}

  private passwordRules = passwordRules

  private get validPassword() {
    return validPassword(this.body.password)
  }

  private get passwordMatch() {
    return passwordMatch(this.body.password, this.body.repeat_password)
  }

  private get appVersion(): string {
    return stateModule.version
  }

  private get loading(): boolean {
    return stateModule.loading
  }

  private created() {
    const username = typeof this.$route.query.username === 'string' ? this.$route.query.username : ''
    const token = typeof this.$route.query.token === 'string' ? this.$route.query.token : ''
    this.body = new ResetPasswordRequest({ username, token })
  }

  private destroyed(): void {
    if (this.submitted) {
      stateModule.setLoading(false)
    }
  }

  private resetError(key: string): void {
    this.$set(this.errors, key, [])
  }

  private async submit(): Promise<void> {
    if (!this.body.isValid) return
    stateModule.setLoading(true)
    this.$set(this, 'errors', {})
    try {
      const { data } = await authService.resetPassword(this.body)

      this.submitted = true
      this.message = data
    } catch (e) {
      if (e.message && e.errors) {
        this.$set(this, 'errors', { message: e.message, ...e.errors })
      } else if (e.errors) {
        this.$set(this, 'errors', e.errors)
      } else if (e.message) {
        this.$set(this, 'errors', { message: e.message })
      }
    } finally {
      stateModule.setLoading(false)
    }
  }
}
